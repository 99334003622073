<template>
  <v-card class="py-3 px-3 text-left checkout-summary" flat>
    <h2>Carrello</h2>
    <v-card-text>
      <div
        class="d-flex mt-n6 mb-6 justify-space-between align-top"
        v-if="isCheckout"
      >
        <PurchaseMinimum></PurchaseMinimum>
        <DeliveryFee></DeliveryFee>
      </div>
      <div class="d-flex justify-space-between align-top">
        <strong class="summary-text">Totale articoli</strong>
        <strong class="summary-value">
          {{
            isOrderView
              ? $n(subordersTotal, "currency")
              : $n(netTotal, "currency")
          }}
        </strong>
      </div>
      <div class=" d-flex justify-space-between align-top ">
        <span class="summary-text">Di cui a peso</span>
        <span class="summary-value">{{
          $n(orderCart.totalPriceVariable, "currency")
        }}</span>
      </div>

      <div class=" d-flex justify-space-between align-top">
        <strong class="summary-text">Tot. costo sacchetti</strong>
        <strong class="summary-value">{{
          $n(orderCart.packageTotal, "currency")
        }}</strong>
      </div>

      <div
        class="d-flex justify-space-between align-top "
        v-for="orderPackage in orderCart.orderPackages"
        :key="orderPackage.orderPackageId"
      >
        <span class="summary-text"
          >{{ orderPackage.package.descr }} <br />
          ({{ orderPackage.quantity }}x{{
            $n(orderPackage.package.cost, "currency")
          }})
        </span>
        <span class="summary-value">{{
          $n(orderPackage.grossTotal, "currency")
        }}</span>
      </div>

      <div
        v-for="gift in orderCart.giftCertificates"
        :key="gift.giftCertificateId"
        class="d-flex align-center"
      >
        <v-btn
          icon
          @click="removeGiftCode(gift.userGiftCertificateId)"
          right
          v-if="!isOrderView"
        >
          <v-icon color="grey lighten-1" x-small>$delete</v-icon>
        </v-btn>
        <span>{{ gift.name }}</span>
      </div>
      <div
        class=" d-flex justify-space-between align-top"
        v-for="gift in orderCart.giftCertificatesToGenerate"
        :key="gift.giftCertificateId"
      >
        <span
          class="summary-text px-2 white--text text-uppercase primary rounded-pill"
          >{{ gift.name }}</span
        >
        <span class="summary-value" v-if="gift.giftTotal">
          {{ $n(gift.giftTotal, "currency") }}</span
        >
      </div>

      <div
        class="d-flex justify-space-between align-top"
        v-if="orderCart.deliveryFee > 0"
      >
        <strong class="summary-text">Spese di consegna</strong>
        <strong class="summary-value">{{
          $n(orderCart.deliveryFee, "currency")
        }}</strong>
      </div>

      <hr />
      <div class="total d-flex justify-space-between align-center">
        <span>TOTALE:</span>
        <OrderGrossTotal
          :item="orderCart"
          :delivered="delivered"
        ></OrderGrossTotal>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import PurchaseMinimum from "@/views/checkout/PurchaseMinimum.vue";
export default {
  name: "CheckoutSummary",
  components: { OrderGrossTotal, PurchaseMinimum },
  props: {
    delivered: { type: Boolean, default: false },
    orderCart: { type: Object, required: true },
    isOrderView: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: true }
  },
  computed: {
    netTotal() {
      return this.orderCart.orderId
        ? this.orderCart.netTotal
        : this.orderCart.totalPrice;
    },
    subordersTotal() {
      var result = null;
      for (var i = 0; i < this.orderCart.suborders.length; i++) {
        result = result + this.orderCart.suborders[i].grossTotal;
      }
      return result;
    }
  },
  methods: {
    removeGiftCode(giftCodeId) {
      this.$emit("removeGiftCode", giftCodeId);
    }
  }
};
</script>
