<template functional>
  <strong class="gross-total">
    {{ parent.$n(props.item.grossTotal, "currency") }}
  </strong>
</template>
<script>
export default {
  name: "props.orderGrossTotal",
  props: {
    item: { type: Object, required: true }
  }
};
</script>
