<template>
  <v-card flat class="card-info-wrapper" @click="edit">
    <div class="d-flex flex-no-wrap justify-space-between align-center">
      <img aria-hidden :src="icon" />
      <div class="card-info pa-2">
        <span class="title-info">{{ text }}</span>
        <span>{{ infoValue }}</span>
      </div>
    </div>
    <v-icon
      small
      color="primary"
      right
      v-if="$vuetify.breakpoint.xs && editable"
    >
      $edit
    </v-icon>
    <v-icon
      small
      color="primary"
      right
      v-if="!$vuetify.breakpoint.xs && editable"
    >
      $edit
    </v-icon>
  </v-card>
</template>
<script>
export default {
  name: "CartInfoCard",
  props: {
    icon: { type: String, required: true },
    text: { type: String, required: true },
    infoValue: { type: String, required: true },
    editable: { type: Boolean, default: true }
  },
  methods: {
    edit() {
      if (this.editable) {
        this.$emit("edit");
      }
    }
  }
};
</script>
