<template>
  <v-card flat class="gift-code">
    <v-row class="d-flex flex-column">
      <v-col cols="12">
        <h3>Hai un codice sconto?</h3>

        <div
          class=" d-flex justify-space-between align-center mb-2"
          v-for="gift in giftCertificatesReceived"
          :key="gift.userGiftCertificateId"
        >
          <span class="summary-text">{{ gift.name }}</span>

          <v-btn
            color="primary"
            class="add-gift-btn"
            small
            v-on:click="addGiftCode(gift.userGiftCertificateId)"
          >
            USA
          </v-btn>
        </div>

        <div class="gift-code-input d-flex align-center">
          <v-text-field
            label="Inserisci codice"
            hide-details="auto"
            v-model="giftCode"
            outlined
            dense
          ></v-text-field>
          <v-btn
            elevation="2"
            medium
            v-on:click="sendGiftCode(giftCode)"
            :disabled="createDisabled"
            color="primary"
            >AGGIUNGI</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss">
.gift-code {
  background-color: $white;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px !important;
  .summary-text {
    font-size: 13px;
    font-weight: 700;
  }

  .gift-code-input {
    margin-top: 10px;
    height: 40px;
    .v-input {
      margin: 0;
      padding: 0;
      height: 100%;
      fieldset {
        border-radius: 8px 0 0 8px;
      }
    }

    .v-btn {
      height: 100%;
      border: none;
      border-radius: 0 8px 8px 0px;
      box-shadow: none !important;
    }
  }
}
</style>

<script>
export default {
  name: "GiftCode",
  props: { giftCertificatesReceived: { type: Array, required: true } },
  data() {
    return {
      giftCode: "",
      createDisabled: false
    };
  },
  methods: {
    addGiftCode(giftCodeId) {
      this.$emit("addGiftCode", giftCodeId);
    },
    sendGiftCode(giftCode) {
      this.$emit("sendGiftCode", giftCode);
    }
  }
};
</script>
