<template>
  <div class="checkout">
    <v-container v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>
    <v-container v-if="getProposals('header-products').length > 0">
      <ProductListSlider
        v-for="proposal in getProposals('header-products')"
        class="py-2 force-mobile-product-card"
        :key="proposal.id"
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>

    <CategoryTitle :category="category" />
    <v-container class="pt-0">
      <v-row>
        <v-col cols="12" md="8" order="last" order-md="first" class="pt-0">
          <DeliveryFee></DeliveryFee>
          <v-card
            flat
            class="accept-alternatives-container mb-6 d-flex"
            v-if="deliveryServiceWithReplaceableItems && showGlobal()"
          >
            <v-avatar class="mt-2"
              ><v-icon color="primary">$replace</v-icon></v-avatar
            >
            <div>
              <v-card-title class="pb-0 d-inline-block accept-title">
                {{ $t("checkout.acceptAlternativesTitle") }}
              </v-card-title>
              <v-card-text class="pb-0">
                <div v-html="$t('checkout.acceptAlternativesSubtitle')"></div>
              </v-card-text>
              <v-card-actions>
                <v-checkbox
                  dense
                  color="primary"
                  hide-details
                  v-model="acceptAlternatives"
                  class="accept-alternatives pt-0 mr-2"
                >
                  <template v-slot:label>
                    <span
                      class="accept-label"
                      v-html="$t('checkout.acceptAlternativesCheckbox')"
                    ></span>
                  </template>
                </v-checkbox>
              </v-card-actions>
            </div>
          </v-card>
          <CartItemList full="true" :isCheckout="true" />

          <v-row no-gutters>
            <v-col cols="12">
              <p class="disclaimer-info">
                {{ $t("checkout.disclaimer") }}
              </p>
            </v-col>
          </v-row>
          <v-alert
            v-if="error"
            type="error"
            border="left"
            transition="v-fab-transition"
          >
            {{ error }}
          </v-alert>
          <v-row class="checkout-actions" :justify="'center'">
            <v-col
              class="my-0 mx-0 my-md-2 mx-md-2 d-flex justify-center flex-md-row flex-column"
            >
              <v-btn
                aria-label="Aggiungi ad una lista"
                outlined
                color="primary"
                x-large
                depressed
                class="bg-white"
                :disabled="cart.totalItems == 0"
                @click="addAllToFavorites"
              >
                AGGIUNGI AD UNA LISTA
              </v-btn>

              <v-btn
                outlined
                color="primary"
                class="bg-white mt-2 mt-md-0 ml-md-2"
                x-large
                depressed
                :disabled="cart.totalItems == 0"
                @click="emptyCartConfirm"
              >
                SVUOTA CARRELLO
              </v-btn>
            </v-col>
            <v-col class="my-auto d-md-flex justify-md-center">
              <v-btn
                color="primary"
                class="payment-btn"
                x-large
                depressed
                :disabled="
                  profileLevel < minProfileLevel || cart.totalItems == 0
                "
                :loading="loading"
                @click="goToPayment"
              >
                Concludi Ordine
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" order="first" order-md="last" class="pt-0">
          <CheckoutCompleteProfile v-if="profileLevel < minProfileLevel" />
          <CheckoutConfirmEmail
            v-if="!cart.user.profile.confirmed"
            :key="cart.refreshTime"
          />
          <CartInfoAddressCard
            :shippingAddress="cart.shippingAddress"
            :editable="editableAddress"
            @edit="openAddressSelector"
          />
          <CartInfoTimeslotCard
            :shippingAddress="cart.shippingAddress"
            :timeslot="cart.timeslot"
            :editable="editableTimeslot"
            @edit="openTimeslotSelector"
          />

          <GiftCode
            :giftCertificatesReceived="giftCertificatesReceived"
            @addGiftCode="addUserGiftCode"
            @sendGiftCode="sendUserGiftCode"
          />

          <CheckoutSummary
            :orderCart="cart"
            @removeGiftCode="removeUserGiftCode"
          />

          <ProposalSlider :proposals="getProposals('sidebar-banner')" />
        </v-col>
      </v-row>
    </v-container>

    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container v-if="getProposals('footer-banner').length > 0">
      <ProposalSlider :proposals="getProposals('footer-banner')" />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.cart {
  position: relative;
  padding-bottom: 56px;
}
.checkout {
  .v-card__title {
    word-break: normal;
  }
  .accept-title {
    font-weight: 600;
  }
  .accept-label {
    font-weight: 600;
    text-decoration: underline;
    color: $text-color;
  }
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CheckoutConfirmEmail from "@/components/cart/CheckoutConfirmEmail.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import GiftCode from "@/components/gift/GiftCode.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";

import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import ListService from "~/service/listService";
import AnalyticsService from "~/service/analyticsService";
import RegistrationService from "~/service/userService";

import login from "~/mixins/login";
import categoryMixins from "~/mixins/category";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapState, mapGetters } from "vuex";
import { forEachCartItem } from "~/service/ebsn";
import get from "lodash/get";
import DeliveryFee from "./DeliveryFee.vue";
// import reduce from "lodash/reduce";

export default {
  name: "Checkout",
  mixins: [login, categoryMixins, cartInfo],
  components: {
    CategoryTitle,
    CartItemList,
    CheckoutSummary,
    GiftCode,
    ProposalSlider,
    CheckoutCompleteProfile,
    CheckoutConfirmEmail,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    ProductListSlider,
    // GiftList
    DeliveryFee
  },
  data() {
    return {
      error: null,
      loading: false,
      minProfileLevel: global.config.minProfileLevel,
      giftCertificatesReceived: [],
      emailKey: 1,
      replaceables: [],
      isReplaceable: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      getCartStatus: "cart/getCartStatus",
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    editableAddress() {
      return !(this.getCartStatus == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    deliveryServiceWithReplaceableItems() {
      return global.config.deliveryServiceWithAlternativesProduct.includes(
        this.getDeliveryServiceId
      );
    },
    acceptAlternatives: {
      get() {
        let value = true;
        var flaggables = [];
        // var found = ;
        var _this = this;
        this.cart.sectors.forEach(sector => {
          sector.cartItems.forEach(cartItem => {
            if (
              get(cartItem, "product.productInfos.IS_REPLACEABLE") &&
              get(cartItem, "product.productInfos.IS_REPLACEABLE") == "true"
            ) {
              flaggables.push(cartItem);
            }
          });
        });
        for (var i = 0; i < flaggables.length; i++) {
          if (
            get(flaggables[i], "cartItemInfo.accept_alternatives", "true") ==
            "true"
          ) {
            value = true;
            break;
          } else {
            value = false;
          }
        }
        _this.isReplaceable = value;
        _this.replaceables = flaggables;
        return value;
      },
      set(value) {
        let itemReplaceables = [];
        this.cart.sectors.forEach(sector =>
          sector.cartItems.forEach(cartItem => {
            if (
              get(cartItem, "product.productInfos.IS_REPLACEABLE") == "true"
            ) {
              itemReplaceables.push(cartItem);
            }
          })
        );
        if (itemReplaceables && itemReplaceables.length > 0) {
          this.setCartItemInfo({
            items: itemReplaceables,
            name: "accept_alternatives",
            value: "" + value
          });
        }
      }
    }
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode",
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      let _this = this;
      const data = await RegistrationService.getUserDetail();
      _this.giftCertificatesReceived = data
        ? data.giftCertificatesReceived
        : [];
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        await _this.lockCart();
        _this.$router.push({
          name: "Payment"
        });
      } catch (err) {
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    setAcceptAlternativeToFalse() {
      let itemReplaceables = [];
      this.cart.sectors.forEach(sector =>
        sector.cartItems.forEach(cartItem => {
          if (get(cartItem, "product.productInfos.IS_REPLACEABLE") == "false") {
            itemReplaceables.push(cartItem);
          }
        })
      );
      if (itemReplaceables && itemReplaceables.length > 0) {
        this.setCartItemInfo({
          items: itemReplaceables,
          name: "accept_alternatives",
          value: "false"
        });
      }
    },
    showGlobal() {
      let globals = [];
      this.cart.sectors.forEach(sector => {
        sector.cartItems.forEach(cartItem => {
          if (get(cartItem, "product.productInfos.IS_REPLACEABLE")) {
            if (
              get(cartItem, "product.productInfos.IS_REPLACEABLE") == "true"
            ) {
              globals.push(cartItem);
            }
          }
        });
      });
      if (globals.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    if (!this.deliveryServiceWithReplaceableItems) {
      this.setAcceptAlternativeToFalse();
    }
    this.setCartItemInfo({
      items: this.replaceables,
      name: "accept_alternatives",
      value: "" + this.isReplaceable
    });
  },
  created() {
    AnalyticsService.beginCheckout(this.cart);
    this.getUserDetails();
  }
};
</script>
