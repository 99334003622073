<template>
  <v-card flat class="card-complete-profile">
    <v-card-title>
      {{ $t("checkout.confirmEmailTitle") }}
    </v-card-title>

    <v-card-subtitle>
      {{ $t("checkout.confirmEmailSubTitle") }}
    </v-card-subtitle>
  </v-card>
</template>
<style scoped>
.card-complete-profile {
  margin-bottom: 15px;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  name: "CheckoutConfirmEmail",
  data() {
    return {
      polling: null
    };
  },
  methods: {
    ...mapActions({ reload: "cart/loadCart" }),
    pollData() {
      this.polling = setInterval(() => {
        this.reload();
        this.$emit("loadCart");
      }, 10000);
    }
  },
  created() {
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  }
};
</script>

<style lang="scss" scoped>
.card-complete-profile {
  background: #ff8484 0% 0% no-repeat padding-box;
  color: white;
  .v-card__title {
    font-size: 20px;
    font-weight: bold;
  }
  .v-card__subtitle {
    color: white;
    font-size: 16px;
  }
  .complete-profile-btn {
    background: white;
    color: #ff8484;
    border: 2px solid #ff8484;
    width: 100%;
  }
}
</style>
